import "./App.css";
import Home from "./Section/Home";
import ItemDisplay from "./Section/ItemDisplay";
import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="min-h-screen w-screen">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<ItemDisplay />} />
      </Routes>
    </div>
  );
}

export default App;
