import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three";
import {
  Model,
  StrongLight,
  SetupScene,
  ModelThumbnailViewer,
} from "./ModelViewer";

import fleet from "../data/fleet";
import { useNavigate } from "react-router-dom";

export default function Fleet() {
  const navigate = useNavigate();
  return (
    <div className="py-8 sm:py-12 md:py-15 px-4 sm:px-6 md:px-10 mb-8 sm:mb-14">
      <div className="mb-6 sm:mb-8 text-3xl sm:text-4xl md:text-[48px]">
        Our Solstrodinary Fleet
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {fleet.map((vehicle, i) => (
          <div
            key={`fleet-item-${i}`}
            className="flex flex-col items-center border border-black cursor-pointer"
            onClick={() => navigate(`/${vehicle.slug}`)}
          >
            <div className="text-base sm:text-lg md:text-[24px] bg-[#FEEEDC] w-full py-4 sm:py-6 px-4 sm:px-[35px] text-center">
              {vehicle.name}
            </div>
            <div className="w-full h-[351px]">
              <ModelThumbnailViewer modelUrl={vehicle.model} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
