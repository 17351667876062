import React, { useState } from "react";

const FAQItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <details
      className="p-4 sm:p-6 md:p-8 border-2 border-black shadow-[0px_4px_black] bg-white rounded-xl sm:rounded-2xl"
      open={isOpen}
    >
      <summary
        className="text-lg sm:text-xl md:text-2xl tracking-wide text-black focus:text-violet-500 items-center justify-between cursor-pointer pr-8 relative"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {question}
        <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
          {isOpen ? "−" : "+"}
        </span>
      </summary>
      {isOpen && (
        <p className="text-base sm:text-lg md:text-xl xl:text-2xl tracking-wide text-black pt-4 sm:pt-6">
          {answer}
        </p>
      )}
    </details>
  );
};

const FAQ = ({ faqs }: { faqs: { question: string; answer: string }[] }) => {
  return (
    <div className="space-y-4 py-6 sm:py-8 px-4 sm:px-6 md:px-10 pt-0">
      <div className="mb-6 sm:mb-8 text-3xl sm:text-4xl md:text-[48px]">
        FAQ
      </div>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
